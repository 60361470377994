import React, { useState } from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import './gallery.scss'

export default function Gallery() {
    const [showWalkBehind, setShowWalkBehind] = useState(false)
    const [showRideOn, setShowRideOn] = useState(false)
    const [showAll, setShowAll] = useState(false)
    let Count = []
    let AllCount = []
    for(var i = 0; i < 6; i++) {
        Count.push(i)
    }
    for(var i = 0; i < 12; i++) {
        AllCount.push(i)
    }
    const makeItHappen = () => {
        console.log("Making it happen")
    }
    const showAllImages = () => {
        setShowAll(!showAll)
        setShowWalkBehind(false)
        setShowRideOn(false)
    }
    const showWalkBehindImages = () => {
        setShowWalkBehind(!showWalkBehind)
        setShowAll(false)
        setShowRideOn(false)
    }
    const showRideOnImages = () => {
        setShowRideOn(!showRideOn)
        setShowAll(false)
        setShowWalkBehind(false)
    }
    
    return (
        <Layout>
                <div className="gallery-header">
                    <h4>Photo <span className="blue">Gallery</span></h4>
                </div>
            <PaddedLayout>
                <div className="page-wrapper">
                <div className="gallery-subheader">
                    <ul>
                    <li><a href onClick={showAllImages} className={showAll ? "active" : ""}>View All</a></li>
                    <li><a href onClick={showWalkBehindImages} className={showWalkBehind ? "active" : ""}>Walk Behind Models</a></li>
                    <li><a href onClick={showRideOnImages} className={showRideOn ? "active" : ""}>Ride on Models</a></li>
                    </ul>
                </div>
                    <div className="featured-images">
                        {Count.map(count => (
                            <div className="image-container"></div>
                        ))}
                    </div>
                    {showAll ? 
                    <div className="all-images">
                        {AllCount.map(count => (
                            <div className="image-container"></div>
                        ))}
                    </div>
                    : ""}

                </div>
            </PaddedLayout>
        </Layout>
    )
}
